define("ember-sortable/modifiers/sortable-handle", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * Modifier to to mark the handle of an item. If this is not supplied the item will be the handle
   *
   * @module drag-drop/draggable-group
   * @example*
   *    <ol {{sortable-group onChange=this.update a11yAnnouncementConfig=this.myA11yConfig}}>
   *      {{#each model.items as |item|}}
   *        <li {{sortable-item model=item}}>
   *          {{item.name}}
   *          <span class="handle" {{sortable-handle}}>&varr;</span>
   *        </li>
   *      {{/each}}
   *    </ol>
   */
  class SortableHandleModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "didSetup", false);
    }
    modify(element /*, positional, named*/) {
      if (!this.didSetup) {
        // take the model and look up the registered element, the tell that element you are the handle
        element.dataset.sortableHandle = true;
        element.tabIndex = '0';
        element.setAttribute('role', 'button');
        this.didSetup = true;
      }
    }
  }
  _exports.default = SortableHandleModifier;
});