window.is_turkmen = getCookie('IS_TURKMEN') === 'true';
const protocol = window.location.protocol;
window.GLOBAL_API_TURKMEN = {
  configs: `${protocol}//configs.adrenalineslot.com`,
  files: `${protocol}//files-v1.adrenalineslot.com`,
  trans: `${protocol}//trans.adrenalineslot.com`,
  media: `${protocol}//media-avif.adrenalineslot.com`,
  map_attacks: `${protocol}//map-attacks.adrenalineslot.com`,
};
window.GLOBAL_API_DEFAULT = {
  configs: `${protocol}//configs.bigstarbet.com`,
  files: `${protocol}//files.bigstarbet.com`,
  trans: `${protocol}//trans.bigstarbet.com`,
  media: `${protocol}//media-avif.bigstarbet.com`,
  map_attacks: `${protocol}//map-attacks.bigstarbet.com`,
}
window.GLOBAL_API = window.is_turkmen ? window.GLOBAL_API_TURKMEN : GLOBAL_API_DEFAULT

window.loadPartnerConfigPromise = null;

checkPartner()

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function domain_from_url(origin) {
  var result
  var match
  if (match = origin.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
    result = match[1]
    if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
      result = match[1]
    }
  }
  return result
}

function checkPartner() {
  $.get("https://ipinfo.io", function(response) {
    window.ip = response.ip;
    window.country = response.country;
  }, "jsonp");
  let origin = window.location.origin;
  let mainDomain = domain_from_url(origin);

  let url = `${window.GLOBAL_API.configs}/${window.location.host}.js`;

  window.loadPartnerConfigPromise = jQuery.ajax({
    type: "GET",
    url: url,
    success: () => {

    },
    dataType: "script",
    cache: true
  });
}
